import * as React from 'react';
import { graphql } from 'gatsby';
import SiteWrapper from '../components/siteWrapper';
import Page from '../components/page';
import Nav from '../components/menu/vereinNav';

const IndexPage = (props) => {
    const node = props.data.allFile.edges[0].node.childMarkdownRemark;
    return (
        <SiteWrapper
            title="Sportverein Kappel 1953 e.V."
            description="Hier erfährst du mehr über den Freiburger Verein aus dem Dreisamtal. 1953 gegründet und mittlerweile mit 4 Abteilungen und unzähligen Angeboten nicht mehr wegzudenken..."
        >
            <Nav />
            <Page
                title={node.frontmatter.title}
                headerImg={node.frontmatter.headerImg}
            >
                {node.html}
            </Page>
        </SiteWrapper>
    );
};

export default IndexPage;

export const query = graphql`
    query LeitbildQuery {
        allFile(
            filter: {
                sourceInstanceName: { eq: "sites" }
                name: { eq: "leitbild" }
            }
        ) {
            edges {
                node {
                    childMarkdownRemark {
                        html
                        frontmatter {
                            title
                            headerImg {
                                childImageSharp {
                                    gatsbyImageData
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;
